@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on Hower */
::-webkit-scrollbar-track:hover {
  background: #555;
}
.react-datepicker__input-container input.dark{
  width: 100%;
  height: 50px;
  border-width: 0;
  border-bottom-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-width: 1px;
  background-color: #525252;
  color: white;
  padding-left: 10px;
  font-size: 12px;
}
.react-datepicker__input-container input.light{
  width: 100%;
  height: 50px;
  border-width: 0;
  border-bottom-color: #8b8b8b;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-width: 1px;
  background-color: #f0f0f0;
  color: #525252;
  padding-left: 10px;
  font-size: 12px;
}